import React, { Fragment, PureComponent } from 'react'
import { Layout, Jumbotron, ImageMd } from '../../components'
import { Link } from 'gatsby'
import '../style.less'
class Menus extends PureComponent {
  componentDidMount () {
    document.body.scrollTop = 0
  }

  render () {
    return (
      <Fragment>
        <Layout
          title={'Menus'}
          location={this.props.location}
          description={'The Woolpack\'s Menus'}
          keywords={[
            'The Woolpack menu',
            'Pub menus',
            'steak dinners',
            'steaks',
            'restaurant menus near me',
            'restaurant menus'
          ]}
        >
          <Jumbotron
            heading={'Menus'}
            subText={'Our menus are updated regularly to include fresh ingredients and seasonal dishes.'}
            image={'food.jpg'}
          />
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-xs 12'>
                <h2 className='para-h2'>Our Food</h2>
                <p>A range of classic British dishes is served all week, with a cracking roast to look forward to every Sunday.</p>
                <p>The roast promises a tempting choice of meats from beef, turkey, gammon, pork and lamb - with all manner of trimmings and fresh, locally sourced, seasonal veg.</p>
                <p>On the Grill menu you will find traditional favourites such as Grilled Gammon Steak, BBQ Rack&apos;s of Ribs and our Black Rock Steaks. There&apos;s also an ever-changing specials board.</p>
                <p>Call us to book your table today.</p>
              </div>
              <div className='col-md-6 col-xs 12' id='opening-times'>
                <h2 className='para-h2'>Food service times</h2>
                <ul className='food-opening-times list-unstyled'>
                  <li>
                    <div className='row'>
                      <div className='col-xs-6'>
                        Monday - Thursday
                      </div>
                      <div className='col-xs-6'>
                        <p>12:00 - 15:00 &amp; 17:00 - 20:00</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className='row'>
                      <div className='col-xs-6'>
                        Friday &amp; Saturday
                      </div>
                      <div className='col-xs-6'>
                        <p>12:00 - 20:30</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className='row'>
                      <div className='col-xs-6'>
                        Sunday
                      </div>
                      <div className='col-xs-6'>
                        <p>12:00 - 17:30</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <p>You can find a copy of our menus to print below</p>
                <ul>
                  <li><a href='/Menus/Main_Menu.pdf' download>Main Menu</a></li>
                  <li><a href='/Menus/Childrens_Menu.pdf' download>Children&apos;s Menu</a></li>
                  <li><a href='/Menus/Sunday_Lunch.pdf' download>Sunday Lunch</a></li>
                </ul>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='para-h2'>Menus</h2>
              </div>
            </div>
            <div className='row '>
              <div className='col-md-6 col-xs-12 pb-xs-4 pb-sm-4 pb-md-4'>
                <div className='menu-link-container'>
                  <Link to='/Menus/Starters-&amp;-Grill'>
                    <ImageMd
                      image={'natchos.jpg'}
                      text={'Starters & Grill Menu'}
                    />
                  </Link>
                </div>
              </div>
              <div className='col-md-6 col-xs-12 pb-xs-4 pb-sm-4 pb-md-4'>
                <div className='menu-link-container'>
                  <Link to='/Menus/Traditional-Favourites/'>
                    <ImageMd
                      image={'tradFav.jpg'}
                      text={'Traditional Favourites'}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-xs-12 pb-xs-4 pb-sm-4 pb-md-4'>
                <div className='menu-link-container'>
                  <Link to='/Menus/Pizza'>
                    <ImageMd
                      image={'pizza.jpeg'}
                      text={'Pizza\'s'}
                    />
                  </Link>
                </div>
              </div>
              <div className='col-md-6 col-xs-12 pb-xs-4 pb-sm-4 pb-md-4'>
                <div className='menu-link-container'>
                  <Link to='/Menus/Vegan-&amp;-Vegetarian'>
                    <ImageMd
                      image={'vegan.png'}
                      text={'Vegan & Vegetarian '}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className='row'>
            <div className='col-md-6 col-xs-12 pb-xs-4 pb-sm-4 pb-md-4'>
                <div className='menu-link-container'>
                  <Link to='/Menus/Sunday-Lunch'>
                    <ImageMd
                      image={'sunday.jpg'}
                      text={'Traditional Sunday Lunch'}
                    />
                  </Link>
                </div>
              </div>
              <div className='col-md-6 col-xs-12 pb-xs-4 pb-sm-4 pb-md-4'>
                <div className='menu-link-container'>
                  <Link to='/Menus/Childrens-Menu'>
                    <ImageMd
                      image={'childrens.jpg'}
                      text={"Children's Menu"}
                    />
                  </Link>
                </div>
              </div>
              {/* <div className='col-md-6 col-xs-12 pb-xs-4 pb-sm-4 pb-md-4'>
                <div className='menu-link-container'>
                  <Link to='/Menus/Desserts'>
                    <ImageMd
                      image={'dessert.jpg'}
                      text={"Dessert's"}
                    />
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </Layout>
      </Fragment>
    )
  }
}

export default Menus
